/**
 * KKH eGK Lichtbild Upload API
 * API to upload a photo for a new eGK.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: apiteam@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiValidationErrorDetailBvs } from './api-validation-error-detail';

/**
 * The errorType dictates which properties will be present (e.g. type=validation means validationErrors should be present)
 */
export interface ApiErrorDetailBvs {
  /**
   * What type of error occured (e.g. validation, technical, logical)
   */
  errorType: ApiErrorDetailBvs.ErrorTypeBvs;
  /**
   * desc
   */
  validationErrors?: Array<ApiValidationErrorDetailBvs>;
  /**
   * Message for logical or technical errors to be presented to the user
   */
  message?: string;
  /**
   * Reference to the error output in server logs, can be used to get more information about technical errors
   */
  logFileCode?: string;
}
export namespace ApiErrorDetailBvs {
  export type ErrorTypeBvs = 'validation' | 'technical' | 'logical';
  export const ErrorTypeBvs = {
    Validation: 'validation' as ErrorTypeBvs,
    Technical: 'technical' as ErrorTypeBvs,
    Logical: 'logical' as ErrorTypeBvs,
  };
}
